import { useContext } from 'react'
import Head from 'next/head'

import { SanityAnyPage } from '@data/sanity/queries/types/page'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getSanityImageUrl } from '@lib/image'
import { ShopContext } from '@lib/shop'

interface HeadSeoProps {
  site: SanitySiteFragment
  page: SanityAnyPage
  canonicalUrl?: string
  productSchema?: string
  blogPostSchema?: string
}

const HeadSeo = ({
  site,
  page,
  canonicalUrl,
  productSchema,
  blogPostSchema,
}: HeadSeoProps) => {
  const { shopifyDomain } = useContext(ShopContext)

  const metaTitle = page.seo?.metaTitle ?? site.seo?.metaTitle
  const metaDescription = page.seo?.metaDesc ?? site.seo?.metaDesc
  const shareTitle = page.seo?.shareTitle ?? site.seo?.shareTitle
  const shareDescription = page.seo?.shareDesc ?? site.seo?.shareDesc
  const shareGraphicUrl = getSanityImageUrl(
    page.seo?.shareGraphic ?? site.seo?.shareGraphic,
    {
      width: 1200,
      height: 630,
    }
  )

  const siteTitle = site.seo?.siteTitle

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no" />
      {!!page.noIndex && <meta name="robots" content="noindex" />}

      {site.settings?.facebookDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={site.settings?.facebookDomainVerification}
        />
      )}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#E7FF86" />
      <meta name="msapplication-TileColor" content="#E7FF86" />
      <meta name="theme-color" content="#E7FF86" />

      <link rel="preconnect" href={`https://${shopifyDomain}`} />
      <link
        rel="preconnect"
        href="https://cdn.sanity.io"
        crossOrigin="anonymous"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" property="og:title" content={shareTitle} />
      <meta name="twitter:title" content={shareTitle} />
      <meta
        name="description"
        property="og:description"
        content={shareDescription}
      />
      <meta name="twitter:description" content={shareDescription} />

      {shareGraphicUrl && (
        <>
          <meta name="image" property="og:image" content={shareGraphicUrl} />
          <meta name="twitter:image" content={shareGraphicUrl} />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:site_name" content={siteTitle} />

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {productSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: productSchema }}
        />
      )}

      {blogPostSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: blogPostSchema }}
        />
      )}
    </Head>
  )
}

export default HeadSeo
